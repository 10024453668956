<template>
  <div class="header container d-flex justify-center align-center">
    <v-row>
      <v-col cols="3" class="d-flex justify-start align-center">
        <v-icon v-if="btnRight" v-on:click="clickBtnRight" class="mr-2">
          {{ btnRight }}
        </v-icon>
        <div v-else></div>
      </v-col>
      <v-col
        cols="6"
        class="title text-center"
        v-if="title"
        @click="titleClick"
      >
        <div class="white--text">{{ title }}</div>
      </v-col>
      <slot name="morBtnLeft"/>
      <v-col  class="d-flex justify-end align-center" v-if="btnLeft">
        <v-icon v-if="btnLeft" v-on:click="clickBtnLeft" class="ml-2">
          {{ btnLeft }}
        </v-icon>
        <div v-else></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["btnRight", "title", "btnLeft"],
  methods: {
    clickBtnRight() {
      this.$emit("clickBtnRight");
    },
    titleClick() {
      this.$emit("titleClick");
    },
    clickBtnLeft() {
      this.$emit("clickBtnLeft");
    },
  },
};
</script>
