<template>
  <div class="header-color">
    <my-header
      :btnLeft="'mdi-information-outline'"
      @clickBtnLeft="menuAbout = true"
      title="הרשמה"
      btnRight="mdi-arrow-right"
      @clickBtnRight="gotoLogin"
    />

    <v-container
      class="form-container d-flex justify-center flex-column body-color px-10"
    >
      <v-row justify="space-around" class="mb-10 mt-10">
        <v-img
          class="d-flex justify-center"
          lazy-src="/imgs/admin/login.png"
          max-height="170"
          max-width="127"
          src="/imgs/admin/login.png"
        ></v-img>
      </v-row>
      <v-form @keyup.enter="submit" ref="form" v-model="valid">
        <v-text-field
          v-model="firstName"
          label="שם פרטי"
          :rules="[(v) => !!v || 'הכנס שם פרטי']"
          required
          prepend-inner-icon="mdi-account"
          autofocus
        />
        <v-text-field
          v-model="lastName"
          label="שם משפחה"
          :rules="[(v) => !!v || 'הכנס שם משפחה']"
          required
          prepend-inner-icon="mdi-account"
        />
        <v-text-field
          v-model="phone"
          type="number"
          :rules="[(v) => !!v || 'הכנס פלאפון']"
          label="פלאפון"
          required
          prepend-inner-icon="mdi-phone"
        />
        <v-text-field
          v-model="address"
          label="כתובת"
          prepend-inner-icon="mdi-home"
        />
        <v-text-field
          @keydown.space.prevent
          v-model="email"
          label="אימייל"
          :rules="[(v) => !!v || 'הכנס אימייל']"
          required
          prepend-inner-icon="mdi-email"
        />
        <v-text-field
          v-model="password"
          :append-icon="!showPassword ? 'mdi-eye-off' : 'mdi-eye-outline'"
          :prepend-inner-icon="'mdi-lock'"
          @click:append="showPassword = !showPassword"
          label="סיסמה"
          :type="showPassword ? 'text' : 'password'"
          :rules="[(v) => !!v || 'הכנס סיסמה']"
          required
        />
        <v-text-field
          v-model="authPassword"
          :append-icon="!showAuthPassword ? 'mdi-eye-off' : 'mdi-eye-outline'"
          :prepend-inner-icon="'mdi-lock'"
          @click:append="showAuthPassword = !showAuthPassword"
          label="אימות סיסמה"
          :type="showAuthPassword ? 'text' : 'password'"
          :rules="[
            (v) => (!!v && authPassword === password) || 'הסיסמאות לא הזהות',
          ]"
          required
        />
        <v-row justify="center">
          <v-col cols="12">
            <v-btn
              :disabled="!valid"
              block
              @click="submit"
              :class="`  pa-8 ${
                !valid ? 'gradient-btn-disabled' : 'gradient-btn'
              }`"
              x-large
              v-text="'הרשמה'"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-bottom-sheet v-model="menuAbout" max-width="300px">
        <about />
      </v-bottom-sheet>
    </v-container>
  </div>
</template>

<script>
import About from "../components/About.vue";
import MyHeader from "../components/MyHeader.vue";
export default {
  components: { MyHeader, About },
  name: "Register",
  data: () => {
    return {
      showPassword: false,
      showAuthPassword: false,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      address: null,
      password: null,
      authPassword: null,
      isAlertMessage: false,
      loading: false,
      valid: false,
      menuAbout: false,
    };
  },
  computed: {},
  methods: {
    gotoLogin() {
      this.$router.push({ name: "Login" });
    },

    async submit() {
      this.valid = false;
      this.loading = true;
      try {
        await this.$store.dispatch("auth/register", {
          email: this.email,
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          address: this.address,
        });
        this.$router.push({ name: "Login" });
      } catch (e) {
        this.valid = true;
      }
      this.loading = false;
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  .form-container {
    width: 80%;
  }
  .progress {
    margin: 20% auto;
  }
}
</style>
